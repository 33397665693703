import { render, staticRenderFns } from "./ModalPreviewImage.vue?vue&type=template&id=581ca00d&scoped=true"
import script from "./ModalPreviewImage.vue?vue&type=script&lang=js"
export * from "./ModalPreviewImage.vue?vue&type=script&lang=js"
import style0 from "./ModalPreviewImage.vue?vue&type=style&index=0&id=581ca00d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "581ca00d",
  null
  
)

export default component.exports