<template>
  <div>
    <b-modal
      v-model="showModal"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <div class="modal-header">
        <button type="button" aria-label="Close" class="close" @click="hide">
          <font-awesome-icon
            icon="times"
            class=" cursor-pointer"
          />
        </button>
      </div>
      <div class="text-center">
        <img :src="imageUrl" alt="loading" class="w-100 h-100" />
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      imageUrl: ""
    };
  },
  methods: {
    show(imageUrl) {
      this.imageUrl = imageUrl;
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}

.modal-body {
  margin: auto;
  text-align: center;
}

::v-deep #modal-loading .modal-content {
  /* background-clip: padding-box; */
  border: unset;
  border-radius: 0.3rem;
  outline: 0;
  background-color: unset;
}
</style>
